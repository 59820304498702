.carousel {
  width: 100%;
  height: 40vh;
  background-color: black;
}

.carouselInner {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.carouselInner .left {
  flex: 2%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  color: #fff;
  cursor: pointer;
}

.carouselInner .center {
  flex: 96%;
  height: 100%;
  display: grid;
  place-items: center;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  color: #7f6e78;
  text-align-last: center;
}

.carouselInner .right {
  flex: 2%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  color: #fff;
  cursor: pointer;
}
