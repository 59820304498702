* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Jost", "Lobster", cursive;
}

body {
  margin: 0;
  font-family: "Jost", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Jost", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Dropdown_missing__3Mb1R {
  text-align: center;
  font-size: larger;
  color: rgba(224, 27, 27, 1);
}

.Dropdown_button__13soh {
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  align-items: stretch;
  text-align: left;
  color: #8ca365;
  font-size: 20px;
  font-weight: 500;
  padding: 0;
  border: none;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #9ca3af;
}

.Dropdown_button__13soh:hover {
  cursor: pointer;
}

.Dropdown_button__13soh:active {
  background-color: rgb(57, 100, 145, 0.3);
}

.Dropdown_optionsContainer__z-qrH {
  display: none;
  overflow: auto;
  max-height: 300px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  background-color: #f7e6d2;
  padding: 16px 0px;
  z-index: 99;
  border-radius: 8px;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
}

.Dropdown_option__mgV_N {
  padding: 16px 24px;
  border: none;
}

.Dropdown_option__mgV_N:hover {
  background-color: #e2c5a3;
}

.Dropdown_show__15cGu {
  display: flex;
}

.Dropdown_disabled__3eIfF {
  pointer-events: none;
  opacity: 0.5;
}

.Dropdown_selected__2VD2K {
  color: #cd4a4c;
}

.SelectChip_container__2K57r {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.SelectChip_chip__3nLR- {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  background: #f7e6d2;
  border-radius: 4px;
  cursor: pointer;
  width: 88px;
  height: 40px;
}

.SelectChip_chip__3nLR-:hover {
  background-color: #f7e6d2;
}

.SelectChip_span__ldWyO {
  width: 17px;
  height: 20px;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #8ca365;
}

.SelectChip_visible__sRKbs {
  opacity: 100;
  cursor: pointer;
  z-index: 1;
}

.SelectChip_option__17QPH {
  display: flex;
  width: inherit;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.SelectChip_option__17QPH:hover {
  color: rgb(188, 45, 45);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.navbar {
  background: #f7e6d2;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 110px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: #8ca365;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-logo-img {
  width: 250px;
}

.nav-logo-img:hover {
  cursor: pointer;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
}

.nav-item {
  height: 110px;
  border-bottom: 5px solid transparent;
}

.nav-item .active {
  color: #cd4a4c;
}

.nav-item:hover {
  border-bottom: 5px solid #8ca365;
  cursor: pointer;
}

.nav-links {
  color: #8ca365;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.activated {
  color: #cd4a4c;
}

.fa-bars {
  color: #8ca365;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-logo-img {
    width: 150px;
    margin-bottom: 62px;
  }

  .nav-menu.active {
    background: #f7e6d2;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #cd4a4c;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    height: 90px;
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.Footer_container__2_4pY {
  bottom: 0;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.Footer_rows__2glW6 {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  justify-content: center;
}

.Footer_primary__1-Apa {
  background: #f7e6d2;
}

.Footer_li__RekY0 {
  list-style: none;
}

.Footer_row__1vSh9 {
  color: #8ca365;
  font-size: 19px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 450px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: start;
}

.Footer_cards__2IgY6 {
  padding: 24px 0px 12px 0px;
  grid-gap: 6px;
  gap: 6px;
}

.Footer_card__Q2zF_ {
  display: flex;
  padding: 0px 4px;
}

.Footer_cardgroup__3_kud {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_footer__3_dQ6 {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: rgb(132, 132, 132);
}

.Footer_a__1CBnx {
  font-size: 16px;
  color: rgb(255, 255, 255);
}

.Footer_a__1CBnx:hover {
  color: rgb(255, 255, 255);
}

.Footer_wrapper__YJNZd {
  display: flex;
  width: calc(500px + 40vw);
  max-width: 100%;
  justify-content: space-evenly;
}

.Footer_row__1vSh9 h4 {
  margin-top: 0px;
  margin-bottom: 8px;
}

.Footer_copyrightStyle__2VbnB {
  height: 100px;
  background: red;
}

@media (min-width: 0px) {
  .Footer_wrapper__YJNZd {
    flex-direction: column;
    align-items: center;
    padding: 24px 24px;
    grid-gap: 14px;
    gap: 14px;
  }
  .Footer_row__1vSh9 {
    text-align: center;
  }
  .Footer_footer__3_dQ6 {
    flex-direction: column;
  }
  .Footer_cards__2IgY6 {
    flex-direction: column;
  }
  .Footer_card__Q2zF_ {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .Footer_wrapper__YJNZd {
    flex-direction: row;
    align-items: flex-start;
    padding: 40px 24px;
    grid-gap: 0px;
    gap: 0px;
  }
  .Footer_row__1vSh9 {
    text-align: left;
  }
  .Footer_footer__3_dQ6 {
    flex-direction: row;
  }
  .Footer_cards__2IgY6 {
    flex-direction: row;
  }
  .Footer_card__Q2zF_ {
    flex-direction: row;
  }
}

.Carousel_carousel__4V5_6 {
  width: 100%;
  height: 40vh;
  background-color: black;
}

.Carousel_carouselInner__2PrAi {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.Carousel_carouselInner__2PrAi .Carousel_left__Fv7kH {
  flex: 2% 1;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  color: #fff;
  cursor: pointer;
}

.Carousel_carouselInner__2PrAi .Carousel_center__pOlVt {
  flex: 96% 1;
  height: 100%;
  display: grid;
  place-items: center;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  color: #7f6e78;
  text-align-last: center;
}

.Carousel_carouselInner__2PrAi .Carousel_right__3GyIX {
  flex: 2% 1;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  color: #fff;
  cursor: pointer;
}

* {
  font-size: 22px;
}

.break {
  height: 110px;
  width: 100%;
  background-color: #8ca365;
  box-shadow: 0.1px 5px 5px #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 44px;
}

.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20%;
  padding: 10px;
  flex-direction: row;
}

.text-container {
  padding: 15px;
}

.flex-container {
  display: flex;
}

.flex-child {
  flex: 1 1;
  margin: 45px 0;
  align-self: center;
  text-align: justify;
}

.flex-child-cert {
  flex: 1 1;
  margin: 45px 0;
  align-self: center;
  text-align: justify;
}

.flex-child:first-child {
  margin-right: 60px;
}

.slogan {
  font-size: 70px;
  font-family: "Lobster", cursive;
  color: #cd4a4c;
}

.title {
  text-align: center;
  text-decoration: underline;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30%;
  padding: 10px;
  flex-direction: column;
}

.flex-container {
  display: flex;
}

.flex-child {
  flex: 1 1;
  margin: 45px 0;
  align-self: center;
}

.about-image {
  width: 500px;
}

.certificate-image {
  margin-right: 0;
  width: 250px;
}

.container {
  width: 100%;
}

.title-container {
  margin: 45px 0;
  display: flex;
  width: 100%;
  justify-content: center;
}

.maps-container {
  display: flex;
  margin: 120px 0;
  justify-content: space-between;
}

.map-container {
  width: 500px;
  height: 35vh;
}

.location-info {
  font-size: 22px;
  padding: 5px;
}

.location-info > a {
  text-decoration: none;
  color: #8ca365;
}

.location-info > a:hover {
  color: #596b3a;
}

.contact-image-container {
  display: flex;
  justify-content: center;
}

.contact-image {
  width: 25%;
}

@media screen and (max-width: 2420px) {
  .about-container {
    margin: 0 18%;
  }
}

@media screen and (max-width: 1520px) {
  .about-container {
    margin: 0 10%;
  }
  .about-image {
    width: 400px;
  }

  .text-container {
    width: 100%;
  }
}

@media screen and (max-width: 1260px) {
  .home-container {
    flex-direction: column;
    margin: 0;
  }
}

@media screen and (max-width: 960px) {
  .flex-container {
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }

  .flex-child {
    width: 100%;
    justify-items: center;
    justify-content: center;
  }

  .flex-child:first-child {
    margin-right: 0;
  }

  .about-image {
    width: 100%;
  }

  #video {
    order: 2;
  }

  #intro-text {
    order: 1;
  }

  #production-image {
    order: 2;
  }

  #production {
    order: 1;
  }

  #sustainability-image {
    order: 2;
  }

  #sustainability {
    order: 1;
  }

  .maps-container {
    flex-direction: column;
    margin: 0;
  }

  .map-container {
    width: 100%;
    height: 35vh;
  }

  .location-info {
    width: 100%;
    font-size: 18px;
  }
}

