* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  bottom: 0;
  width: 100%;
  height: fit-content;
}

.rows {
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: center;
}

.primary {
  background: #f7e6d2;
}

.li {
  list-style: none;
}

.row {
  color: #8ca365;
  font-size: 19px;
  height: fit-content;
  max-width: 450px;
  width: fit-content;
  text-align: start;
}

.cards {
  padding: 24px 0px 12px 0px;
  gap: 6px;
}

.card {
  display: flex;
  padding: 0px 4px;
}

.cardgroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: rgb(132, 132, 132);
}

.a {
  font-size: 16px;
  color: rgb(255, 255, 255);
}

.a:hover {
  color: rgb(255, 255, 255);
}

.wrapper {
  display: flex;
  width: calc(500px + 40vw);
  max-width: 100%;
  justify-content: space-evenly;
}

.row h4 {
  margin-top: 0px;
  margin-bottom: 8px;
}

.copyrightStyle {
  height: 100px;
  background: red;
}

@media (min-width: 0px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
    padding: 24px 24px;
    gap: 14px;
  }
  .row {
    text-align: center;
  }
  .footer {
    flex-direction: column;
  }
  .cards {
    flex-direction: column;
  }
  .card {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .wrapper {
    flex-direction: row;
    align-items: flex-start;
    padding: 40px 24px;
    gap: 0px;
  }
  .row {
    text-align: left;
  }
  .footer {
    flex-direction: row;
  }
  .cards {
    flex-direction: row;
  }
  .card {
    flex-direction: row;
  }
}
